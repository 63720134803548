import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { openConfirm, openModal, openSidemenu, showAlert, eventLayout } from 'object/src/actor/Reducer';
import { connect } from 'react-redux';
import * as actions from 'object/src/actor/Action';

// 기본적인 스타일 선언.
import './css/Default.css';
import './css/react-draft-wysiwyg.css';

import { Header, Footer, Storage, cs, Button, Sidebar, Layout, Svg, Error } from 'object/src';
import { ST } from 'svc/Lang';
import { SIZE, URL } from 'svc/Enum';
import cx from 'classnames/bind';
import { STAT } from './object/src/Config';
import styled from 'styled-components';

import {
  Homepage, About, Board, Privacy, Qna, File
} from 'view';

import * as adm from 'admin';
import { Util } from 'object/src/Utils';

const StyledObject = styled.div`{
  &.user-layout { ${cs.bg.white} ${cs.min.height('100vh')} ${cs.disp.autoflex} ${cs.disp.flexgrow()}
    .ft-frame { ${cs.pos.relative} 
      .ft-box { ${cs.pos.relative} 
        ${cs.w.full} ${cs.max.wxl} ${cs.m.center()} ${cs.font.left}
        .menu-bar { 
          ${cs.border.bottom} ${cs.m.v10} ${cs.p.b0} ${cs.font.right} ${cs.font.color(cs.color.lightprimary)}
          ${cs.font.thickbold}
        }
        .info-bar { ${cs.font.sm} ${cs.font.line(20)} ${cs.p.bottom(100)} ${cs.font.lightgray} ${cs.m.t30} ${cs.p.h10}
          .ib-title { ${cs.p.r5} }
          .ib-text { ${cs.p.r20} }
          .ib-link { ${cs.font.white} ${cs.m.r20} ${cs.mouse.pointer} &:hover { ${cs.font.underline} ${cs.font.primary} }  }
          .copyright { ${cs.m.t5} }
        }
        .comp-name { ${cs.align.ltop} ${cs.font.t0} ${cs.font.lightprimary} }
      }
    }
    .m-footer {
      ${cs.anim.showin('200ms', 0, 1, 'm-foot-anim-show')} 
    }

    @media screen and (max-width : 800px) {
    }
    
    @media screen and (max-width : 600px) { 
      .m-footer .ft-frame .ft-box {
        .comp-name { ${cs.disp.none} }
        .menu-bar { ${cs.font.center}
          .button { ${cs.p.h10} 
            .btn-label { ${cs.font.sm} } 
          } 
        } 
      }
    }
  
    @media screen and (max-width : 480px) { }
  }

  &.no-admin { ${cs.bg.white}
    .p-noti { ${cs.w.full} ${cs.font.t3} ${cs.font.center} ${cs.align.ycenter} ${cs.font.darkgray} ${cs.font.himelody} 
      ${cs.mouse.pointer}
    }
  }

  &.admin-layout { ${cs.bg.dark}
    .admin-frame { ${cs.bg.dark} ${cs.font.white} ${cs.min.wxl}
      &.menuout { .ad-page { ${cs.m.h0} ${cs.p.h10} } }
    }
    .guide-btn { ${cs.pos.fixed} ${cs.z.get(199999)} ${cs.left(0)} ${cs.bottom(20)} 
      ${cs.font.white} ${cs.font.thickbold} ${cs.font.lg} ${cs.opac.get(0.3)} ${cs.max.w(240)}
      &:hover { ${cs.opac.show} }
    }    

    @media screen and (max-width : 1024px) { 
      .admin-frame { ${cs.min.width('100%')} }
      .guide-btn { ${cs.left('none')} ${cs.right(0)}  ${cs.bottom(5)} }
    }
  
    @media screen and (max-width : 600px) { 
    }
  
    @media screen and (max-width : 480px) { }
    }
  }

  &.noti-box {
    ${cs.pos.fixed} ${cs.right(0)} ${cs.top(0)} ${cs.disp.inblock}
    ${cs.float.right} ${cs.z.popup} ${cs.noselect}

    .nt-button {
      ${cs.pos.relative} ${cs.right(20)} ${cs.top(10)} ${cs.disp.inblock}
      ${cs.size.get(40)} ${cs.float.right} ${cs.border.radius(50)} 
      ${cs.p.get(6)} ${cs.box.line} ${cs.anim.slidein('200ms', '120%', '0', 'noti-btn')} 
      .nt-count { 
        ${cs.align.rtop} ${cs.left(-2)} ${cs.top(0)} ${cs.bg.red} ${cs.font.white}
        ${cs.font.thickbold} ${cs.font.center} ${cs.border.radius(50)} ${cs.font.xs}
        ${cs.h.get(16)} ${cs.font.line(16)} ${cs.w.get(24)} ${cs.z.over}
      }

      .svg-path { ${cs.fill.get('yellow !important')} ${cs.opac.get("1 !important")} }

      .alarm { ${cs.anim.flicking("500ms", '1', '0.3')} }
    }

    .nt-frame {
      ${cs.font.xs} ${cs.font.white} ${cs.w.get(200)} ${cs.z.over}
      ${cs.bg.get('rgba(0,0,0,0.9)')} ${cs.h.fit} ${cs.p.a10} ${cs.align.rbottom}
      ${cs.top(30)} ${cs.right(50)} ${cs.border.radius(5)} ${cs.mouse.pointer}
      ${cs.disp.none}

      .n-li { ${cs.h.get(34)} ${cs.border.bottom} ${cs.pos.relative} ${cs.p.t5} ${cs.border.dark}
        .n-title { ${cs.disp.block} }
        .n-date { ${cs.disp.inblock} ${cs.align.rbottom} ${cs.bottom(2)} ${cs.font.gray} }
        &:last-child { ${cs.border.none} }
      }

      &.show { ${cs.disp.block} ${cs.anim.slideup('200ms', '-120%', '0', 'noti-anim')} }
    }
  }
}`;

class App extends React.PureComponent {
  constructor(props) {
    super(props);

    const guid = Storage.getLocalItem('guid');
    if (!guid) {
      Storage.setLocalItem('guid', Util.getUuid());
    }

    this.menu = {
      home: [
        { name: ST.MENU.HOME, url: URL.ROOT, comp: Homepage, title: ST.MENU.HOME, hide: true },
        { name: ST.MENU.BOARD, url: URL.BOARD, comp: Board, title: ST.MENU.BOARD, hide: true },
        { name: ST.MENU.QNA, url: URL.QNA, comp: Qna, title: ST.MENU.QNA, hide: true },
        { name: ST.MENU.FILE, url: URL.FILE, comp: File, title: ST.MENU.FILE, hide: true },
        { name: '', url: URL.PRIVACY, comp: Privacy, title: '', hide: true },
      ],
      admin: [
        // { name: ST.ADMIN.NAVI.MAIN, url: URL.ADMIN.MAIN, comp: adm.Main, title: ST.ADMIN.NAVI.MAIN },
        { name: ST.ADMIN.NAVI.MENU, url: URL.ADMIN.MENU, comp: adm.Menu, title: ST.ADMIN.NAVI.MENU },
        { name: ST.ADMIN.NAVI.BOARD, url: URL.ADMIN.BOARD, comp: adm.Board, title: ST.ADMIN.NAVI.BOARD },
        { name: ST.ADMIN.NAVI.QNA, url: URL.ADMIN.QNA, comp: adm.Qna, title: ST.ADMIN.NAVI.QNA },
        { name: ST.ADMIN.NAVI.PRODQNA, url: URL.ADMIN.PRODQNA, comp: adm.ProdQna, title: ST.ADMIN.NAVI.PRODQNA },
        { name: ST.ADMIN.NAVI.FILE, url: URL.ADMIN.FILE, comp: adm.File, title: ST.ADMIN.NAVI.FILE },
        { name: ST.ADMIN.NAVI.PAGE, url: URL.ADMIN.PAGE, comp: adm.Page, title: ST.ADMIN.NAVI.PAGE, hide: true },
        { name: ST.ADMIN.NAVI.SYSINIT, url: URL.ADMIN.SYSINIT, comp: adm.SysInits, title: ST.ADMIN.NAVI.SYSINIT, hide: true },
        { name: ST.ADMIN.NAVI.ABOUT, url: URL.ADMIN.ABOUT, comp: adm.About, title: ST.ADMIN.NAVI.ABOUT, hide: true, type: 'system' },
        { name: ST.ADMIN.NAVI.SETTING, url: URL.ADMIN.SETTING, comp: adm.Setting, title: ST.ADMIN.NAVI.SETTING, hide: true, type: 'system' },
        { name: ST.ADMIN.NAVI.MANAGEMENT, url: URL.ADMIN.MANAGEMENT, comp: adm.Management, title: ST.ADMIN.NAVI.MANAGEMENT, hide: true, type: 'system' },
        { name: ST.ADMIN.NAVI.LOGOUT, url: URL.LOGOUT, comp: null, title: ST.ADMIN.NAVI.LOGOUT },
      ]
    };

    const { pathname = null } = window.location;
    const isadmin = pathname.indexOf('/admin') === 0;
    if (isadmin) {
      this.state = { menus: [...this.menu.admin], loaded: true, isadmin };
    } else {
      this.state = { menus: this.menu.home, loaded: true, isadmin: false };
    }

    global.loginurl = URL.ROOT;
    global.openConfirm = this.openConfirm;
    global.openModal = this.openModal;
    global.showAlert = (v) => this.props.showAlert(v);
    global.closeConfirm = (v) => this.props.openConfirm({ ...v, show: false });
    global.closeModal = (v) => this.props.openModal({ ...v, show: false });
    global.onLoaded = this.props.onReady;
  }

  doLoadPageAndMenu = () => {
    actions.doSelect(URL.API.TOKEN).then(({ result, config }) => {
      this.setState({ loaded: true });
    })
  }

  openConfirm = (value) => {
    value.type = value.type == null ? '' : value.type;
    value.show = true;
    value.size = value.size == null ? '' : value.size;
    value.className = value.className == null ? '' : value.className;
    value.title = value.title == null ? ST.ALARM : value.title;
    value.ok = value.ok == null ? ST.OK : value.ok;
    value.cancel = value.cancel == null ? ST.CANCEL : value.cancel;
    value.msg = value.msg == null ? ST.IS_DELETE : value.msg;
    value.children = value.children || null;
    value.onClicked = value.onClicked == null ? null : value.onClicked;
    this.props.openConfirm(value);
  }

  openModal = (value) => {
    value.show = true;
    value.className = value.className == null ? '' : value.className;
    value.title = value.title == null ? ST.ALARM : value.title;
    value.children = value.children == null ? null : value.children;
    value.data = value.data == null ? {} : value.data;
    value.size = value.size == null ? '' : value.size;
    value.desc = value.desc == null ? '' : value.desc;
    value.state = value.state == null ? STAT.N : value.state;
    value.ok = value.ok == null ? ST.OK : value.ok;
    value.cancel = value.cancel == null ? ST.CANCEL : value.cancel;
    this.props.openModal(value);
  }

  render() {
    const props = this.props;
    const { menus = null, isadmin = false, loaded = false } = this.state;

    if (!menus) return null;

    const user = Storage.getLocalItem(Storage.key.userinfo);
    const { pathname } = window.location;

    return (
      <Router>
        <Switch>
          {/* 로그인 권한이 필요하지 않은 화면들... */}
          <Route exact path={URL.PRIVACY} render={(matchProps) => <Privacy {...matchProps} />} /> {/* 개인정보취금방침 및 이용약관 */}
          <Route exact path={URL.ABOUT} render={(matchProps) => <About {...matchProps} />} /> {/* 회사소개 */}
          <Route exact path={URL.ADMIN.ROOT} render={(matchProps) => <adm.Login {...matchProps} />} /> {/* 관리자 로그인 */}
          <Route exact path={URL.ADMIN.LOGIN} render={(matchProps) => <adm.Login {...matchProps} />} /> {/* 관리자 로그인 */}
          <Route exact path={URL.LOGOUT} render={(matchProps) => { Storage.logout(); window.location.href = URL.ROOT; return null; }} /> {/* 로그아웃 */}

          {/* 관리자 메뉴 */}
          {isadmin &&
            menus.map((item, index) => {
              // 로그인을 하지 않은 상태면 로그인 화면으로 이동
              if (!user && pathname.indexOf(URL.ADMIN.ROOT) >= 0 && pathname !== URL.ADMIN.ROOT) {
                setTimeout(() => {
                  Util.logout();
                  window.location.href = URL.ADMIN.ROOT;
                }, 1000);
                return <Error className={'t3'} title={ST.LOGINADMIN} />
              }

              // 사용자 권한으로 관리자 메뉴를 진입할 경우...
              if (user && user.auth === 'user') {
                return (
                  <StyledObject className={'no-admin'}>
                    <span className={'p-noti'} onClick={() => window.location.href = URL.ADMIN.ROOT}>{ST.NOADMIN}</span>
                  </StyledObject>
                )
              }
              // 관리자의 정상적인 진입
              else {
                if (user && user.auth === 'system') menus.map(a => a['type'] && a['type'] === 'system' && (a.hide = false));
                return (<LayoutAdmins key={String(index)} exact path={item.url}
                  component={item.comp} {...item} {...props} menus={menus} />)
              }
            })}

          {/* 사용자 메뉴 */}
          {!isadmin && menus.map((item, index) => {
            return (<LayoutUsers key={String(index)} exact path={item.url}
              component={item.comp} {...item} {...props} menus={menus} loaded={loaded} />)
          })
          }

          <Route path={URL.ADMIN.GUIDE} render={(matchProps) => <adm.GuideBox {...matchProps} />} /> {/* 도움말 */}

          {/* 그외의 화면 접근 에러 */}
          <Route component={Error} {...props} />
        </Switch>
      </Router>
    );
  };
};

/**
 * 사용자 레이아웃
 */
const LayoutUsers = (props) => {

  const [loaded, setLoaded] = useState(false);
  const [company, setCompany] = useState({});
  const [menus, setMenus] = useState(null);
  const [pos, setPos] = useState(0);
  const [header, setHeader] = useState({});

  // const [config, setConfig] = useState([]);
  // const [theme, setTheme] = useState('white-theme');

  useEffect(() => {
    actions.doSelect(URL.API.APP).then((res) => {
      const { value } = res;

      let json = {};
      res.header && res.header.map(a => json[a.skey] = a.sval);
      setHeader(json);

      json = {};
      res.company && res.company.map(a => json[a.skey] = a.sval);
      setCompany(json);

      if (!res.company || res.company.length < 1) {
        global.openConfirm({
          type: 'info', cancel: false, msg: ST.ADMIN_LOGIN, size: 'sm',
          onClicked: (isOk) => {
            (isOk) && (window.location.href = URL.ADMIN.ROOT);
          }
        });
      }

      const param = actions.getParam();
      let index = 0;
      if (param) {
        if (param.rowid) index = value.findIndex(a => String(a.rowid) === String(param.rowid));
        else if (param.menuid) index = value.findIndex(a => String(a.rowid) === String(param.menuid));
      }
      setPos(index < 0 || index >= value.length ? 0 : index);
      setMenus(value);
    });

    global.onLoaded = (isok) => {
      setLoaded(isok);
      props.onReady && props.onReady();
    }

    global.oauth = 'user';
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickMenu = (e, item) => {
    (item) && actions.go(URL.HOME, { rowid: item.rowid });
  }

  const Component = props.component;

  if (!menus || !menus[pos]) return null;
  return (
    <Route render={matchProps => {
      global.hist = matchProps.history;
      matchProps.history.listen((location, action) => {
        window.location.href = location.pathname;
      })

      return <StyledObject className={cx("user-layout")}>
        <Header title={company.name} onClick={() => actions.go('/', {})} align={header.align || 'right'}
          maxWidth={SIZE[(header && header.size) || SIZE.lg.label].width} menus={'show'}
          options={{ font: { type: header.font || 'Hi Melody' } }} list={menus.filter(a => a.visible === "Y")}
          pos={pos} root={URL.ROOT} history={matchProps.history} onClickMenu={onClickMenu} />
        <Component {...matchProps} showAlert={global.showAlert} data={menus[pos]}
          openModal={global.openModal} openConfirm={global.openConfirm} />
        {loaded &&
          <Footer className={'dark m-footer'} value={company.name || ''} height={'fit-content'}>
            <div className={'ft-box'}>
              <span className={'comp-name'}>{`${company.name || ''}`}</span>
              <div className={'menu-bar'}>
                <Button className={'menu'} title={ST.COMPANY.INFO} onClick={(e) => window.location.href = URL.ABOUT} />
                <Button className={'menu'} title={ST.COMPANY.BOARD} onClick={(e) => actions.go(URL.BOARD, { type: null })} />
                <Button className={'menu'} title={ST.COMPANY.PRIVATE} onClick={(e) => actions.go(URL.PRIVACY, { type: 'pri' })} />
                <Button className={'menu'} title={ST.COMPANY.PUBLIC} onClick={(e) => actions.go(URL.PRIVACY, { type: 'pub' })} />
                <Button className={'menu'} title={ST.COMPANY.QNA} onClick={(e) => actions.go(URL.QNA, { type: null })} />
              </div>
              <div className={'info-bar'}>
                <div><span>{company.addr}</span><span>{`(${ST.COMPANY.BOSS}: ${company.boss || ''})`}</span></div>
                <div>{`${ST.COMPANY.LICENSE}${company.license || ''}`}</div>
                {company.bizlicense && <div>
                  <span className={'ib-title'}>{`${ST.COMPANY.BIZ_LINCESE}${company.bizlicense || ''}`}</span>
                  {company.bizurl && <span className={'ib-link'} onClick={() => window.open(company.bizurl)}>{ST.COMPANY.BIZ_URL}</span>}
                </div>}
                <div className={'as'}>
                  <span><span className={'ib-title'}>{ST.COMPANY.TEL}</span><span className={'ib-text'}>{company.phone || ''}</span></span>
                  <span><span className={'ib-title'}>{ST.COMPANY.EMAIL}</span><span className={'ib-text'}>{company.email || ''}</span></span>
                  {company.fax && <span><span className={'ib-title'}>{ST.COMPANY.FAX}</span><span className={'ib-text'}>{company.fax || ''}</span></span>}
                </div>
                {company.bank && <div>{`${ST.COMPANY.BANK}: ${company.bank || ''}`}</div>}
                <div className={'copyright'}>{`${ST.COPYRIGHT(company.name_eng || '', company.year || '', true)}`}</div>
              </div>
            </div>
          </Footer>
        }
      </StyledObject>
    }} />
  )
};

/**
 * 관리자 레이아웃
 */
const LayoutAdmins = (props) => {
  // const [noti, setNoti] = useState(null);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'company', skey: 'name' }).then(({ result }) => {
      setCompany(result && result.length > 0 ? result[0].sval : '');
    });

    document.getElementById("body-frame").classList.add("admin-body");

    global.onLoaded = (isok) => {
      props.onReady && props.onReady();
    }
    global.oauth = 'admin';

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { ADMIN } = URL;
  const Component = props.component;

  const onEvent = (value) => {
    // setNoti(value);
  }

  const onClickSysinit = () => {
    actions.doUpdate(URL.API.ADMIN.SETTING, { stag: 'system', skey: 'init', sval: 'popclose' }).then(() => {
      window.location.href = URL.ADMIN.SYSINIT;
    });
  }

  return (
    <Route {...props.computedMatch} render={matchProps => {
      global.hist = matchProps.history;

      return <AdminLayout title={company ? company.toUpperCase() : ''} menus={props.menus}
        root={ADMIN.MAIN} sidebar={'240px'} topbar={'60px'} onEvent={onEvent}
        eventLayout={props.eventLayout} matchProps={matchProps} >
        <Component {...matchProps} showAlert={global.showAlert}
          openModal={global.openModal} openConfirm={global.openConfirm} />
        <Button className={'guide-btn trans bottom right'} title={ST.ADMIN.SYSINIT.GUIDE} onClick={onClickSysinit} />
      </AdminLayout>
    }} />
  )
};

const AdminLayout = (props) => {
  const { title, menus, root, sidebar, topbar } = props;
  return <StyledObject className="admin-layout" >
    <Sidebar title={title} list={menus} root={root} sidebarW={sidebar} topbarH={topbar}
      eventLayout={(value) => props.eventLayout && props.eventLayout(value)} />
    <Layout className="admin-frame" leftPadding={sidebar} topPadding={topbar}>
      {props.children}
    </Layout>
    <Notibox onEvent={props.onEvent} />
  </StyledObject>
}

const INTERVAL = 1 * 60 * 1000;
var timer = null;
var pcount = 0;

const Notibox = (props) => {
  const [show, setShow] = useState(false);
  const [list, setList] = useState(null);
  const [count, setCount] = useState(0);
  const [alarm, setAlarm] = useState(false);

  useEffect(() => {
    let body = document.getElementById('body');
    body && body.addEventListener('mouseup', onResize);
    doReload();
    timer = setInterval(() => doReload(), INTERVAL);  // 1분마다

    return () => {
      body = document.getElementById('body');
      body && body.removeEventListener('mouseup', onResize);
      timer && clearInterval(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResize = (e) => {
    const { type, width } = Util.getScreenType();
    global['device'] = { type, width };

    const a = Util.isSelfClick(e, (item) => {
      return item.indexOf("n-li") >= 0;
    });
    if (a) return;

    setShow(false);
  }

  const doReload = () => {
    actions.doSelect(URL.API.ADMIN.NOTI).then((res) => {
      if (!res || res.count < 1 || res.count === pcount) return;
      pcount = res.count;
      const array = res && res.value.map(a => {
        if (Util.isJson(a.sval)) {
          a['value'] = a.sval && Util.parseJson(a.sval);
          a['title'] = a.value && a.value.title;
        } else {
          a['value'] = a.sval;
          a['title'] = a.sval;
        }
        a['time'] = a.ctime && Util.toStringSymbol(a.ctime, "/").substr(5, 18);
        return a;
      })
      setList(array);
      setCount(res ? res.count : 0);

      props.onEvent && props.onEvent({ list: array, count: count });

      setAlarm(true);
      setTimeout(() => setAlarm(false), 1000);
    })
  }

  const onClick = (e) => {
    e.stopPropagation();
    setShow(true);
  }

  const onSelect = (item, e) => {
    if (item && item.value) {
      actions.doUpdate(URL.API.ADMIN.NOTI, { ...item }).then(({ code, result }) => { });

      const { url = null, param = null } = item.value;
      if (url && url.indexOf('http') === 0) {
        window.open(url);
      } else {
        url && actions.go(url, param && { ...param });
      }
      setCount(count > 0 ? count - 1 : 0);
      props.onEvent && props.onEvent({ count: count > 0 ? count - 1 : 0 });
    }
    setShow(false);
  }

  const onClear = (eid, e) => {
    e.stopPropagation();
    const values = list.map(a => a.rowid);
    actions.doUpdate(URL.API.ADMIN.NOTI_CLEAR, { values }).then(({ code, result }) => {
      const { err } = result;

      if (err) return;
      if (result && result.list) {
        const array = result.list.map(a => {
          if (Util.isJson(a.sval)) {
            a['value'] = a.sval && Util.parseJson(a.sval);
            a['title'] = a.value && a.value.title;
          } else {
            a['value'] = a.sval;
            a['title'] = a.sval;
          }
          a['time'] = a.ctime && Util.toStringSymbol(a.ctime, "/").substr(5, 18);
          return a;
        })
        setList(array);
        setShow(false);
        setTimeout(() => setShow(true), 200);

        setCount(result.count || 0);
        props.onEvent && props.onEvent({ list: result.list, count: result.count || 0 });
      } else {
        setList(null);
        setShow(false);
        setCount(0);
        props.onEvent && props.onEvent({ list: null, count: 0 });
      }
    });
  }

  return <StyledObject className={'noti-box'}>
    {/* noti 메시지 개수 표시 아이콘 */}
    {count > 0 && <div className={cx("nt-button")}>
      <span onClick={onClick}>
        <Svg className={cx("md black")} icon='alarm' onClick={(eid, e) => onClick(e)} />
        {count > 0 && <span className={cx('nt-count', { alarm })}>{count > 99 ? "99+" : count}</span>}
      </span>
    </div>}
    {/* noti 리스트 프레임 */}
    <div className={cx("nt-frame", { show })}>
      <ul>
        {list && list.map((item, i) => {
          const { title, time } = item;
          return <li key={i} className={'n-li'} onClick={(e) => onSelect(item, e)}>
            <span className={'n-title'}>{`${title}`}</span>
            <span className={'n-date'}>{`${time}`}</span>
          </li>
        })}
      </ul>
      <div className={"nt-foot"}>
        {list && list.length > 0 && <Button className={'n-li sm full'} onClick={onClear} title={ST.ADMIN.NOTI.CLEAR(list.length)} />}
      </div>
    </div>
  </StyledObject>
}

const mapDispatchToProps = (dispatch) => {
  return {
    openConfirm: (obj) => dispatch(openConfirm(obj)),
    openModal: (obj) => dispatch(openModal(obj)),
    showAlert: (obj) => dispatch(showAlert(obj)),
    openSidemenu: (obj) => dispatch(openSidemenu(obj)),
    eventLayout: (obj) => dispatch(eventLayout(obj)),
  };
};

export default connect(null, mapDispatchToProps)(App);